import Vue from "vue";
import "./install";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import AOS from "aos";
import axios from "axios";

import "normalize.css/normalize.css";
import "tailwindcss/tailwind.css";
import "../node_modules/aos/dist/aos.css";
import "vant/lib/index.css";
// loading组件
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { SchemaForm as VanSchemaForm } from "@formily/vant";
//百度地图指令
import BaiduMap from "vue-baidu-map";

Vue.component("VanSchemaForm", VanSchemaForm);
Vue.use(VueLoading, {
  color: "#409EFF",
  isFullPage: false,
  loader: "dots",
  width: 60,
  height: 30,
  backgroundColor: "#ffffff",
  opacity: 0.5,
  zIndex: 999
});
Vue.prototype.$loadingOverlay = Vue.prototype.$loading; // element-ui里面也有$loading，会相互覆盖
Vue.use(BaiduMap, {
  ak: "FqLptAnLuVCQvNkkLyGcvurc0FnP1KVI",
  plugins: ["Mapvgl", "Mapv", "MapvThree"]
});

import ElementUI from "element-ui";
import "@/theme/index.scss";
import locale from "element-ui/lib/locale/lang/zh-CN";

import "@/icons";

Vue.use(ElementUI, {
  locale,
  size: "medium"
});
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.use(AOS);
import "./permission";
import "./permissionV2";
// 组件权限指令
import "./directive";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
AOS.init(); //初始化动画
Vue.prototype.$axios = axios;
