<template>
  <div v-if="details !== null">
    <div class="table_content">
      <!-- 订单基本信息 -->
      <span class="table_name">基本信息</span>
      <table class="table_one" border="1">
        <tr>
          <td class="td_label">订单号</td>
          <td class="td_value">{{ details.extOrderNo }}</td>
        </tr>
        <tr>
          <td class="td_label">订单金额</td>
          <td class="td_value">{{ details.price }}</td>
        </tr>
        <tr>
          <td class="td_label">创建时间</td>
          <td class="td_value">{{ details.createTime }}</td>
        </tr>
        <tr>
          <td class="td_label">状态</td>
          <td class="td_value">{{ computeOrderState(details) }}</td>
        </tr>
        <tr>
          <td class="td_label">出单账号</td>
          <td class="td_value">{{ details.name }}</td>
        </tr>
      </table>
    </div>
    <van-divider />

    <!-- 保障内容 -->
    <VanSchemaForm
      :schema="details.document.schema"
      :value="details.data"
      toSchema
      :formOptions="{ readPretty: true }"
    />
    <VanSchemaForm
      :schema="details.insureDocument.schema"
      :value="details.insureData"
      toSchema
      :formOptions="{ readPretty: true }"
    >
      <VanField label="总保费">
        <template #input>
          <span class="price">{{ details.price }}</span>
        </template>
      </VanField>
      <VanField label=""> </VanField>
    </VanSchemaForm>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { OrderState } from "../../../utils/order-state-compute";
export default {
  data() {
    return {
      id: this.$route.params.id,
      details: null
    };
  },
  mounted() {
    this.loadDetail();
  },
  methods: {
    ...mapActions("order", ["detail"]),
    computeOrderState({
      insureState,
      underwritingState,
      payState,
      issueState,
      invoiceState,
      withdrawState,
      confirmState
    }) {
      return new OrderState(
        insureState,
        underwritingState,
        payState,
        issueState,
        invoiceState,
        withdrawState,
        confirmState
      ).state;
    },
    loadDetail() {
      this.detail(this.id).then(res => {
        this.details = res.data.data;
        this.loading = false;
      });
    }
  }
};
</script>
<style scoped>
.card {
  font-size: 14px;
}
.table,
th,
td {
  /* border: 1px solid black; */
  font-size: 14px;
  color: #646566;
}
.td_label {
  width: 100px;
  height: 30px;
}
.table_content {
  padding: 10px;
}
.table_name {
  font-size: 14px;
  color: #969799;
}
</style>
