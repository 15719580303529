<template>
  <div>
    <van-cell-group title="基本信息">
      <van-field v-model="form.extPolicyNo" label="保单号" readonly />
      <van-field v-model="form.applicantName" label="出险人姓名" readonly />
      <van-field v-model="form.idCard" label="身份证" readonly />
      <van-field v-model="form.contactPhone" label="联系电话" readonly />
      <van-field v-model="form.outTime" label="出险时间" readonly />
      <van-field v-model="form.place" label="地点" readonly />
      <van-field v-model="form.insuredPassage" label="保险事故经过" readonly />
      <van-field v-model="form.hospital" label="就诊医院" />
      <van-field v-model="form.estimatedAmount" label="预估金额" readonly />
      <van-field v-model="form.typeStr" label="案件类型" readonly />
      <van-field v-model="form.createTime" label="创建时间" readonly />
      <van-field v-model="form.stateStr" label="状态" readonly />
      <van-field v-model="form.supplementStateStr" label="补充状态" readonly />
    </van-cell-group>
    <van-cell-group title="资料文件">
      <ul v-for="file in this.form.reportFileList" :key="file.id">
        <li class="lic">
          <a :href="file.fileUrl">{{ file.fileName }}</a>
        </li>
      </ul>
    </van-cell-group>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { downloadHostJbb } from "@/environment";
export default {
  data() {
    return {
      form: {
        id: this.$route.params.id,
        extPolicyNo: null,
        applicantName: null,
        idCard: null,
        contactPhone: null,
        outTime: null,
        place: null,
        insuredPassage: null,
        hospital: null,
        estimatedAmount: null,
        requiredInformation: null,
        claimAmount: null,
        type: null,
        typeStr: null,
        createTime: null,
        createId: null,
        state: null,
        stateStr: null,
        supplementState: null,
        supplementStateStr: null,
        reportFileList: []
      },
      showPicker: false,
      typeColumns: [
        { text: "死亡", value: 1 },
        { text: "伤残", value: 2 }
      ]
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    ...mapActions("report", ["detail"]),
    get() {
      this.detail(this.form.id).then(res => {
        const data = res.data.data;
        this.form = data;
        this.form.typeStr = this.typeToStr(data.type);
        this.form.stateStr = this.stateToStr(data.state);
        this.form.supplementStateStr = this.supplementStateToStr(
          data.supplementState
        );
        this.form.reportFileList.forEach(item => {
          item.fileUrl = `${downloadHostJbb}` + item.fileUrl;
        });
      });
    },
    typeToStr(type) {
      if (type === 1) {
        return "死亡";
      } else if (type === 2) {
        return "伤残";
      } else if (type === 3) {
        return "医疗";
      } else if (type === 4) {
        return "财产";
      } else if (type === 5) {
        return "其他";
      }
    },
    stateToStr(state) {
      if (state === 0) {
        return "待受理";
      } else if (state === 1) {
        return "已受理";
      } else if (state === 2) {
        return "已上传";
      } else if (state === 3) {
        return "理赔中";
      } else if (state === 4) {
        return "已结案";
      }
    },
    supplementStateToStr(supplementState) {
      if (supplementState === 1) {
        return "保司已受理";
      } else if (supplementState === 2) {
        return "资料补充";
      } else if (supplementState === 3) {
        return "保司已审核";
      } else if (supplementState === 4) {
        return "结案";
      }
    }
  }
};
</script>
<style lang="less" scoped>
.lic {
  margin-top: 5px;
  margin-left: 15px;
  font-size: 14px;
  color: blue;
}
</style>
