<template>
  <div class="xmax">
    <!-- <a :href="hrefEbbProduct" target="_blank">to ebaobei product</a> -->
    <!-- <a :href="hrefEbbOrder" target="_blank">to ebaobei order</a> -->
    <!-- 产品列表 -->
    <div id="iframe-container" class="iframe-con">
      <iframe
        :src="hrefJbbProduct"
        scrolling="auto"
        frameborder="0"
        class="trend-container2"
        id="iframe"
      ></iframe>
    </div>
  </div>
</template>

<script>
import environment from "@/environment";
export default {
  name: "index",
  data() {
    return {
      tokenJbb: localStorage.getItem("AuthorizationJbb"),
      token: localStorage.getItem("Authorization")
    };
  },
  computed: {
    hrefJbbProduct() {
      return (
        environment.toJbbHost + "/iframe/product?Authorization=" + this.token
      );
    }
  },
  watch: {
    searchForm: {
      handler() {
        // this.load();
      },
      deep: true
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped>
.trend-container2 {
  width: 100%;
  height: 100%;
}
.iframe-con {
  height: 1300px;
}
</style>
