<template>
  <div class="selWrap">
    <!-- 吸顶查询 -->
    <van-sticky>
      <van-search
        v-model="form.extPolicyNo"
        placeholder="保单号"
        @search="onSearch"
        @cancel="onCancel"
      />
      <!-- 下拉菜单 -->
      <van-dropdown-menu>
        <van-dropdown-item
          v-model="form.supplementState"
          @change="change"
          :options="optionSupplementState"
        />
        <van-dropdown-item
          v-model="form.state"
          @change="change"
          :options="optionState"
        ></van-dropdown-item>
      </van-dropdown-menu>
    </van-sticky>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      offset="300"
      :error.sync="error"
      error-text="请求失败，点击重新加载"
    >
      <ul v-for="item in list" :key="item.id" :title="item.extPolicyNo">
        <div class="card-container" @click="onDetail(item)">
          <van-row>
            <van-col span="12">保单号</van-col>
            <van-col span="12">{{ item.extPolicyNo }}</van-col>
          </van-row>
          <van-row>
            <van-col span="12">出险人姓名</van-col>
            <van-col span="12">{{ item.applicantName }}</van-col>
          </van-row>
          <van-row>
            <van-col span="12">出险时间</van-col>
            <van-col span="12">{{ item.outTime }}</van-col>
          </van-row>
          <!-- <van-row>
            <van-col span="12">身份证</van-col>
            <van-col span="12">{{ item.idCard }}</van-col>
          </van-row> -->
          <!-- <van-row>
            <van-col span="12">联系电话</van-col>
            <van-col span="12">{{ item.contactPhone }}</van-col>
          </van-row> -->
          <van-row>
            <van-col span="12">补充状态</van-col>
            <van-col span="12">
              <span
                v-if="item.supplementState === 1"
                style="font-weight:bold;color: #dc9105"
                >{{ supplementStateToStr(item) }}</span
              >
              <span
                v-if="item.supplementState === 2"
                style="font-weight:bold ;color: #eb5b46"
                >{{ supplementStateToStr(item) }}</span
              >
              <span
                v-if="item.supplementState === 3"
                style="font-weight:bold ;color: #09eb2c"
                >{{ supplementStateToStr(item) }}</span
              >
              <span
                v-if="item.supplementState === 4"
                style="font-weight:bold; color: #303133"
                >{{ supplementStateToStr(item) }}</span
              >
            </van-col>
          </van-row>
          <van-row>
            <van-col span="12">创建人</van-col>
            <van-col span="12">{{ item.createName }}</van-col>
          </van-row>
          <van-row>
            <van-col span="12">状态</van-col>
            <van-col span="12">
              <span id="stateStr" :style="getStyle(item)">
                {{ stateToStr(item) }}</span
              >
            </van-col>
          </van-row>
          <!-- 分割线 -->
          <van-divider />
        </div>
      </ul>
    </van-list>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { el } from "date-fns/locale";
export default {
  data() {
    const supplementStates = [
      { text: "补充状态", value: null },
      { text: "保司已受理", value: 1 },
      { text: "资料补充", value: 2 },
      { text: "保司已审核", value: 3 },
      { text: "结案", value: 4 }
    ];
    const states = [
      { text: "状态", value: null },
      { text: "待受理", value: 0 },
      { text: "已受理", value: 1 },
      { text: "已上传", value: 2 },
      { text: "理赔中", value: 3 },
      { text: "已结案", value: 4 }
    ];
    return {
      loading: false,
      finished: false,
      error: false,
      loadEnd: false,
      list: [],
      form: {
        page: 1,
        pageSize: 10,
        extPolicyNo: null,
        supplementState: null,
        state: null
      },
      optionSupplementState: supplementStates,
      optionState: states
    };
  },

  methods: {
    ...mapActions("report", ["pagination"]),
    onLoad() {
      let timer = setTimeout(() => {
        this.getList(); // 调用上面方法,请求数据
        this.form.page++;
        this.finished && clearTimeout(timer);
      }, 100);
    },
    getList() {
      const _this = this;
      this.pagination({ ...this.form }).then(res => {
        const data = res.data.data;
        if (data.rows.length === 0) {
          // 判断获取数据条数若等于0
          this.list = []; // 清空数组
          this.finished = true; // 停止加载
        }
        // 若数据条数不等于0
        this.total = data.records; // 给数据条数赋值
        //调整数据格式
        const rows = data.rows.map(function(item) {
          // 拼接产品名和方案名
          item._productAndCombo = item.productName + " | " + item.comboName;
          //转换状态
          // const state = _this.computeOrderState(item);
          // if (state !== undefined) {
          //   item._stateStr = state.includes("已确认") ? "已确认" : state;
          // }
          return item;
        });
        this.list.push(...rows); // 将数据放入list中
        this.loading = false; // 加载状态结束
        // 如果list长度大于等于总数据条数,数据全部加载完成
        if (this.list.length >= data.records) {
          this.finished = true; // 结束加载状态
        }
      });
    },
    supplementStateToStr(row) {
      if (row.supplementState === 1) {
        return "保司已受理";
      } else if (row.supplementState === 2) {
        return "资料补充";
      } else if (row.supplementState === 3) {
        return "保司已审核";
      } else {
        return "结案";
      }
    },
    stateToStr(row) {
      if (row.state === 0) {
        return "待受理";
      } else if (row.state === 1) {
        return "已受理";
      } else if (row.state === 2) {
        return "已上传";
      } else if (row.state === 3) {
        return "理赔中";
      } else if (row.state === 4) {
        return "已结案";
      }
    },
    onSearch() {
      this.form.page = 1;
      this.list = [];
      this.onLoad();
    },
    onCancel() {
      console.log("取消");
    },
    getStyle(row) {
      let color = null;
      const font = "font-weight:bold;";
      if (row.state === 0) {
        color = "color:#FEE5A5";
      } else if (row.state === 1) {
        color = "color:#dc9105";
      } else if (row.state === 2) {
        color = "color:#eb5b46";
      } else if (row.state === 3) {
        color = "color:#09eb2c";
      } else if (row.state === 4) {
        color = "color:#303133";
      }
      return font + color;
    },
    change() {
      this.form.page = 1;
      this.list = [];
      this.onLoad();
    },
    onDetail(row) {
      this.$router.push({
        name: "mobile-report-detail",
        params: {
          id: row.id
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.card-container {
  padding: 2px 10px 0px 20px;
  font-size: 12px;
  color: #323322;
}
.selWrap /deep/ .van-dropdown-menu span {
  font-size: 12px;
}
.selWrap /deep/ .van-dropdown-menu .van-dropdown-menu__bar {
  height: 38px;
}
</style>
