export class State {
  constructor(value) {
    if (value == undefined || value === null) value = 0;
    let _value = Number(value);
    this.value = _value;
    switch (_value) {
      case 0: {
        this.label = "未开始";
        break;
      }
      case 1: {
        this.label = "进行中";
        break;
      }
      case 2: {
        this.label = "已完成";
        break;
      }
      case 3: {
        this.label = "已中止";
        break;
      }
      case 4: {
        this.label = "已取消";
        break;
      }
    }
  }

  get started() {
    return this.value >= 1;
  }

  get processing() {
    return this.value == 1;
  }

  get completed() {
    return this.value == 2;
  }

  get failed() {
    return this.value == 3;
  }

  get canceled() {
    return this.value == 4;
  }
}

/**
 * 订单状态计算
 */
export class OrderState {
  /**
   * string
   * 订单状态字面量
   */
  state;
  /**
   *
   * @param {Number} insure 投保状态
   * @param {Number} underwriting  核保状态
   * @param {Number} pay  支付状态
   * @param {Number} issue 出单状态
   */
  constructor(insure, underwriting, pay, issue, invoice, withdraw, confirm) {
    this.insureState = new State(insure);
    this.underwritingState = new State(underwriting);
    this.payState = new State(pay);
    this.issueState = new State(issue);
    this.invoiceState = new State(invoice);
    this.withdrawState = new State(withdraw);
    this.confirmState = new State(confirm);
    if (this.withdrawState.started) {
      if (this.withdrawState.completed) {
        this.state = "已退保";
      } else if (this.withdrawState.failed) {
        this.state = "退保失败";
      } else if (this.withdrawState.canceled) {
        this.state = "退保取消";
      } else {
        this.state = "退保中";
      }
    } else if (this.invoiceState.started) {
      if (this.invoiceState.completed) {
        this.state = "已开票";
      } else if (this.invoiceState.failed) {
        this.state = "开票失败";
      } else if (this.invoiceState.canceled) {
        this.state = "开票取消";
      } else {
        this.state = "开票中";
      }
    } else if (this.issueState.started) {
      if (this.issueState.completed) {
        this.state = "已出单";
      } else if (this.issueState.failed) {
        this.state = "出单失败";
      } else if (this.issueState.canceled) {
        this.state = "出单取消";
      } else {
        this.state = "待出单";
      }
    } else if (this.payState.started) {
      if (this.payState.completed) {
        this.state = "已支付";
      } else if (this.payState.failed) {
        this.state = "支付失败";
      } else if (this.payState.canceled) {
        this.state = "支付取消";
      } else {
        this.state = "待支付";
      }
    } else if (this.underwritingState.started) {
      if (this.underwritingState.completed) {
        this.state = "已核保";
      } else if (this.underwritingState.failed) {
        this.state = "核保失败";
      } else if (this.underwritingState.canceled) {
        this.state = "核保取消";
      } else {
        this.state = "待核保";
      }
    } else if (this.insureState.started) {
      if (this.insureState.completed) {
        this.state = "已投保";
      } else if (this.insureState.failed) {
        // this.state = "投保成功";
        this.state = "投保失败";
      } else if (this.insureState.canceled) {
        this.state = "投保取消";
      } else {
        this.state = "待投保";
      }
    } else if (this.confirmState.started) {
      if (this.insureState.completed) {
        this.state = "已确认";
      } else if (this.insureState.failed) {
        this.state = "确认失败";
      } else if (this.insureState.canceled) {
        this.state = "确认取消";
      } else {
        this.state = "待确认";
      }
    } else {
      this.state = "待确认";
    }
  }
}
