import request from "@/utils/request";
export function pagination(criteria) {
  return request.get("jbb/report", {
    params: {
      ...criteria
    }
  });
}
export function detail(id) {
  return request.get(`jbb/report/${id}`);
}
