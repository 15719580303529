<template>
  <div class="selWrap" v-show="showSelWrap">
    <!-- <div class="xiding"> -->
    <!-- 搜索框 -->
    <van-sticky>
      <van-search
        v-model="formJbb.name"
        placeholder="产品名称"
        @search="onSearchJbb"
        @cancel="onCancel"
      />
      <!-- 垂直滚动通知栏 -->
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="item in newsJbb" :key="item.id">
            {{ item.name }}
          </van-swipe-item>
        </van-swipe>
        <!-- <div slot="right-icon" class="more">>>更多</div> -->
      </van-notice-bar>
      <!-- 下拉菜单 -->
      <van-dropdown-menu>
        <van-dropdown-item
          :value="formJbb.riskId"
          :options="option1Jbb"
          :title="riskNameJbb"
          @change="change1Jbb"
        />
        <van-dropdown-item
          :value="formJbb.insuranceCompanyId"
          :options="option2Jbb"
          :title="companyNameJbb"
          @change="change2Jbb"
        />
      </van-dropdown-menu>
    </van-sticky>
    <!-- </div> -->
    <!-- 列表 -->
    <van-list
      v-model="loadingJbb"
      :finished="finishedJbb"
      finished-text="没有更多了"
      @load="onLoadJbb"
      offset="300"
      :error.sync="errorJbb"
      error-text="请求失败，点击重新加载"
    >
      <ul v-for="item in listJbb" :key="item.id" :title="item.name">
        <div class="card-container">
          <van-card
            :tag="item.recommendStateStr"
            :title="item.name"
            :thumb="item.url"
          >
            -->
            <div slot="desc">
              <material-block :block="material(item).content"></material-block>
            </div>
            <!--  价格插槽-->
            <div slot="price">
              <span class="price-value">￥{{ item.startPrice }}</span
              >元起
            </div>
            <!-- 投保按钮插槽 -->
            <div slot="footer">
              <van-button
                type="info"
                class="tb_button"
                size="mini"
                @click="onDetailJbb(item.sid, item.id)"
                >投保</van-button
              >
              <van-button
                type="plain"
                class="tb_button fx_button"
                size="mini"
                @click="onShare(item)"
                >分享</van-button
              >
            </div>
          </van-card>
        </div>
      </ul>
    </van-list>

    <div class="mask"></div>
    <!-- 分享弹窗 -->
    <van-dialog
      use-slot
      title="分享"
      v-model="shareDialogVisibile"
      confirmButtonText="关闭"
    >
      <!-- 二维码 -->
      <div class="flex flex-row">
        <div class="flex-1">
          <VueQrcode
            :value="shareLink"
            :options="{ width: 200 }"
            tag="img"
          ></VueQrcode>
        </div>
        <!-- 复制链接 -->
        <div class="flex-1" style="position: relative;">
          <div style="float:right;margin-top:50px;">
            <el-button
              class="btn"
              data-clipboard-target="#bar"
              type="primary"
              size="mini"
              @click="onCopyShareLink"
            >
              复制链接 </el-button
            >&nbsp;{{ copyState }}
          </div>
        </div>
      </div>
      <p :data-clipboard-text="shareLink" id="bar" style="opacity:0;height:1px">
        {{ shareLink }}
      </p>
    </van-dialog>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import { mapActions } from "vuex";
import MaterialBlock from "@/components/material";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { downloadHostJbb, toJbbHost } from "@/environment";
export default {
  components: {
    MaterialBlock,
    VueQrcode
  },
  computed: {
    newsGroup() {
      const size = 3;
      return this.news.reduce((p, c, i) => {
        if (p[Math.floor(i / size)]) {
          p[Math.floor(i / size)].push(c);
        } else {
          p.push([c]);
        }
        return p;
      }, []);
    }
  },
  data() {
    return {
      memberId: localStorage.getItem("sid"),
      showSelWrap: true,
      active: 0,
      loadingJbb: false, // 是否处在加载状态
      finishedJbb: false, // 是否已加载完成
      errorJbb: false, // 是否加载失败
      loadEndJbb: false,
      listJbb: [],
      riskNameJbb: null,
      companyNameJbb: null,
      newsJbb: [],
      formJbb: {
        page: 1,
        pageSize: 10,
        shelfState: true,
        name: null,
        insuranceCompanyId: null, // 选中公司id
        riskId: null // 选中险种id
      },
      option1Jbb: [
        {
          text: "产品大类",
          value: null
        }
      ],
      option2Jbb: [
        {
          text: "公司分类",
          value: null
        }
      ],
      materialsInit: {
        id: null,
        productId: null,
        name: "",
        type: null,
        documentId: null,
        scope: null,
        createTime: null,
        rank: null,
        titleType: null,
        material: {
          type: "root-block",
          content: {
            type: "block",
            title: { type: "simple-text", content: "" },
            content: {
              type: "",
              content: null
            }
          }
        }
      },
      shareDialogVisibile: false, // 分享弹窗
      shareLink: null,
      copyState: ""
    };
  },
  mounted() {
    this.getJbbRisk();
    this.getJbbCompany();
    this.getJbbNews();
  },
  methods: {
    ...mapActions("product", [
      "paginationJbb",
      "allRiskJbb",
      "listCompanyJbb",
      "listNewsJbb",
      "createShareLink"
    ]),

    onShare({ id }) {
      this.shareLink = null;
      this.copyState = "";
      this.createShareLink(id).then(res => {
        this.shareLink = res.data.data;
        this.shareDialogVisibile = true;
      });
    },
    getJbbNews() {
      this.newsJbb = [];
      this.listNewsJbb({ type: 2 }).then(res => {
        this.newsJbb = res.data.data.slice(0, 6);
      });
    },
    material(row) {
      if (row.material !== null) {
        let content = row.material.content.content.content;
        if (
          content === null ||
          content === "" ||
          this.getStrNumInParentStr(content, "</p>") < 4
        ) {
          return row.material || this.materialsInit.material;
        } else {
          var pos = null;
          var i = 0;
          while (i < 3) {
            //循环3次
            pos = content.indexOf("</p>", pos + 4);
            i++;
          }

          row.material.content.content.content =
            // content.substr(0, this.find(content, "</p>", 1) + 4) +
            content.substr(0, pos) + "<p>......</p>";
        }
      } else {
        return this.materialsInit.material;
      }
      // 找到第三个</p>出现的位置
      return row.material || this.materialsInit.material;
    },
    getJbbRisk() {
      this.option1Jbb = [
        {
          text: "产品大类",
          value: null
        }
      ];
      this.allRiskJbb().then(res => {
        let data = res.data.data;
        data = data.map(item => {
          return { text: item.abbreviation, value: item.id };
        });
        this.option1Jbb = this.option1Jbb.concat(data);
      });
    },
    getJbbCompany() {
      this.option2Jbb = [
        {
          text: "公司分类",
          value: null
        }
      ];
      this.listCompanyJbb({ type: 2 }).then(res => {
        let data = res.data.data;
        data = data.map(item => {
          return { text: item.simpleName, value: item.id };
        });
        this.option2Jbb = this.option2Jbb.concat(data);
      });
    },
    change1Jbb(value) {
      if (value !== null) {
        this.riskNameJbb = this.option1Jbb.find(
          item => item.value === value
        ).name;
      } else {
        this.riskNameJbb = "产品大类";
      }
      this.formJbb.riskId = value;
      this.formJbb.page = 1;
      this.listJbb = [];
      this.onLoadJbb();
    },
    change2Jbb(value) {
      if (value !== null) {
        this.companyNameJbb = this.option2Jbb.find(
          item => item.value === value
        ).name;
      } else {
        this.companyNameJbb = "公司分类";
      }
      this.formJbb.insuranceCompanyId = value;
      this.formJbb.page = 1;
      this.listJbb = [];
      this.onLoadJbb();
    },
    onSearchJbb() {
      this.formJbb.page = 1;
      this.listJbb = [];
      this.onLoadJbb();
    },
    onCancel() {
      console.log("取消");
    },
    async onLoad() {
      // 若加载条到了底部;
      let timer = setTimeout(() => {
        // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
        this.getList(); // 调用上面方法,请求数据
        this.form.page++; // 分页数加一
        this.finished && clearTimeout(timer); //清除计时器
      }, 100);
    },
    async onLoadJbb() {
      // 若加载条到了底部;
      let timer = setTimeout(() => {
        // 定时器仅针对本地数据渲染动画效果,项目中axios请求不需要定时器
        this.getListJbb(); // 调用上面方法,请求数据
        this.formJbb.page++; // 分页数加一
        this.finishedJbb && clearTimeout(timer); //清除计时器
      }, 100);
    },
    // // 加载失败调用方法
    onRefresh() {
      if (this.platform === "ebb") {
        this.form.page = 1; // 分页数赋值为1
        this.finished = false; // 清空列表数据
        this.loading = true; // 将 loading 设置为 true，表示处于加载状态
        this.list = []; // 清空数组
        this.onLoad(); // 重新加载数据
      }
    },
    // 获取列表数据方法
    async getListJbb() {
      // this.list = [];
      this.paginationJbb({ ...this.formJbb }).then(res => {
        const data = res.data.data;
        if (res.length === 0) {
          // 判断获取数据条数若等于0
          this.listJbb = []; // 清空数组
          this.finishedJbb = true; // 停止加载
        }
        // 若数据条数不等于0
        this.total = data.records; // 给数据条数赋值
        //调整数据格式
        const rows = data.rows.map(function(item) {
          item.url = `${downloadHostJbb}` + item.url;
          if (item.recommendState == 1) {
            item.recommendStateStr = "推荐";
          } else {
            item.recommendStateStr = null;
          }
          return item;
        });
        this.listJbb.push(...rows); // 将数据放入list中
        this.loadingJbb = false; // 加载状态结束
        // 如果list长度大于等于总数据条数,数据全部加载完成
        if (this.listJbb.length >= data.records) {
          this.finishedJbb = true; // 结束加载状态
        }
      });
    },
    onDetailJbb(pid, id) {
      window.location.href = `${toJbbHost}/mobile/order/${id}?pid=${pid}&mid=${this.memberId}`;
    },
    //工具：字符串含子字符串个数
    getStrNumInParentStr(parentStr, childStr) {
      if (typeof parentStr !== "string" || typeof childStr !== "string") {
        return 0;
      }

      if (parentStr.length < childStr.length) {
        return 0;
      }

      let nNum = 0;
      for (let i = 0; i < parentStr.length; i++) {
        let bOkFlag = true;
        let k = i;
        for (let n = 0; n < childStr.length; k++, n++) {
          if (parentStr[k] !== childStr[n]) {
            bOkFlag = false;
            break;
          }
        }

        if (bOkFlag) {
          nNum++;
        }
      }

      return nNum;
    },
    onCopyShareLink() {
      // 构造一个element

      var clipboard = new Clipboard(".btn"); //绑定数据标签的类名
      clipboard.on("success", e => {
        this.copyState = "复制成功";
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        this.copyState = "该浏览器或手机权限不支持复制功能";
        // 释放内存
        clipboard.destroy();
      });
    }
  }
};
</script>
<style lang="less" scoped>
.trend-container2 {
  width: 100%;
}
.van-card {
  border: #e5e7eb solid 1px;
  border-radius: 0.375rem;
  margin-bottom: 0.5rem;
}
.selWrap {
  height: 100%;
}
.vanTabs {
  height: 100%;
  padding-bottom: 0;
}
.selWrap /deep/ .van-tabs .van-tabs__content {
  //less 语法的样式穿透
  height: 100%;
}
.selWrap /deep/ .van-dropdown-menu span {
  //less 语法的样式穿透
  font-size: 12px;
}
.selWrap /deep/ .van-dropdown-menu .van-dropdown-menu__bar {
  height: 38px;
}
.price-value {
  color: #1989fa;
  font-size: 18px;
}
.tb_button {
  width: 60px;
}
.fx_button {
  border: #dcdfe6 solid 1px;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}
.more {
  color: rgb(64, 158, 255, 1);
}
.selWrap /deep/ .van-tabs .van-tabs__content .van-tab__pane .van-notice-bar {
  font-size: 12px;
}
.selWrap /deep/.channel-tabs {
  .van-tab {
    border-right: 1px solid #edeff3;
  }
}
.selWrap
  /deep/
  .van-list
  .card-container
  .van-card
  .van-card__header
  .van-card__content
  .van-card__title {
  font-size: 14px;
  font-weight: bold;
}
</style>
