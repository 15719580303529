<template>
  <div class="container">
    <van-cell-group title="案件管理">
      <van-cell title="理赔案件" is-link to="/mobile/report/list"> </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentRate: null,
      text: "测试",
      gradientColor: {
        "0%": "#3fecff",
        "100%": "#6149f6"
      },
      activeNames: ["1"],
      time: 30 * 60 * 60 * 100
    };
  },
  methods: {
    start() {
      this.$refs.countDown.start();
    },
    pause() {
      this.$refs.countDown.pause();
    },
    reset() {},
    finish() {}
  }
};
</script>
<style scoped>
.container {
  padding: 5px;
  background-color: #f7f8fa;
  height: 100%;
}
</style>
