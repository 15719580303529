/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import {
  login,
  info,
  changePassword,
  pagination,
  details,
  edit,
  add,
  deleteItem,
  loginEbb,
  loginJbb,
  register
} from "@/api/user";
import {
  setToken,
  removeToken,
  setTokenEbb,
  removeTokenEbb,
  setTokenJbb,
  removeTokenJbb
} from "@/utils/token";
import { resetRouter } from "@/router";
import instance from "@/utils/request";

const state = {
  user: {},
  roles: [],
  isAnonymousUser: true
};

const getters = {
  userDetails: state => state.user,
  user: state => state.user,
  roles: state => state.roles,
  isUserExisted: state => !state.isAnonymousUser
};

const mutations = {
  setUser: (state, user) => {
    state.user = user;
  },
  setRoles: (state, roles) => (state.roles = roles),
  setIsAnonymousUser: (state, v) => (state.isAnonymousUser = v)
};

const actions = {
  logout({ commit }) {
    console.log("退出登录");
    commit("setUser", {});
    commit("setRoles", []);
    removeToken(); // 移除token
    removeTokenEbb(); //移除ebaobei的token
    removeTokenJbb(); //移除jbaobao的token
    commit("setIsAnonymousUser", true);
    instance.defaults.headers.common["Authorization"] = null;
    // 退出登录时重新生成路由
    // https://github.com/JesseZhao1990/blog/issues/64
    resetRouter();
  },
  login(_, { username, password }) {
    return new Promise((resolve, reject) => {
      login(username, password)
        .then(res => {
          const { data } = res.data;
          setToken(data);
          instance.defaults.headers.common["Authorization"] = data;
          resolve(res);
        })
        .catch(e => reject(e));
    });
  },
  loginEbb(_, { username, password }) {
    return new Promise((resolve, reject) => {
      loginEbb(username, password)
        .then(res => {
          const { data } = res.data;
          setTokenEbb(data);
          // 在实例已创建后修改默认值
          // instance.defaults.headers.common["AuthorizationEbb"] = data;
          resolve(res);
        })
        .catch(e => reject(e));
    });
  },
  loginJbb(_, { username, password }) {
    return new Promise((resolve, reject) => {
      loginJbb(username, password)
        .then(res => {
          const { data } = res.data;
          setToken(data);
          instance.defaults.headers.common["Authorization"] = data;
          resolve(res);
        })
        .catch(e => reject(e));
    });
  },
  info({ commit }) {
    return new Promise((resolve, reject) => {
      info()
        .then(res => {
          const { data } = res.data;
          if (!data || data === "anonymousUser") {
            // 匿名用户，构造一个匿名用户信息
            commit("setUser", {});
            commit("setRoles", ["ROLE_ANONYMOUS"]);
            commit("setIsAnonymousUser", true);
          } else {
            commit("setUser", data);
            commit("setRoles", data.roles);
            commit("setIsAnonymousUser", false);
            localStorage.setItem("userId", data.userId);
          }
          resolve(res);
        })
        .catch(err => {
          commit("setUser", {});
          commit("setRoles", []);
          reject(err);
        });
    });
  },
  changePassword(_, { origin, password }) {
    return changePassword(origin, password);
  },
  pagination(_, criteria) {
    return pagination(criteria);
  },
  details(_, id) {
    return details(id);
  },
  edit(_, json) {
    return edit(json);
  },
  add(_, form) {
    return add(form);
  },
  deleteItem(_, id) {
    return deleteItem(id);
  },
  register(_, form) {
    return register(form);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
